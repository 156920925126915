export const UPDATE_GLOBAL_MODAL_VISIBLE = "UPDATE_GLOBAL_MODAL_VISIBLE";
export const UPDATE_GLOBAL_MODAL_TIMER = "UPDATE_GLOBAL_MODAL_TIMER";
export const TRIGGER_MODAL = "TRIGGER_MODAL";
export const CLEAR_MODAL = "CLEAR_MODAL";

export function updateGlobalModalVisible(visible) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GLOBAL_MODAL_VISIBLE,
      visible: visible,
    });
  };
}

export function updateGlobalModalTimer(visible) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GLOBAL_MODAL_TIMER,
      visible: visible,
    });
  };
}

export function triggerModal(payload) {
  return (dispatch) => {
    dispatch({
      type: TRIGGER_MODAL,
      payload: payload,
    });
  };
}

export function clearModal() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_MODAL,
    });
  };
}
