import localStorage from "local-storage";
import { get, isEqual } from "lodash";
import {
  CLEAR_MODAL,
  TRIGGER_MODAL,
  UPDATE_GLOBAL_MODAL_TIMER,
  UPDATE_GLOBAL_MODAL_VISIBLE,
} from "../actions/app-actions";
import { persistRedux } from "../config";

const INITIAL_STATE = {
  modalVisible: false,
  modalType: "",
  modalRedirection: "",
  modalNotifyTime: null,
  exchange:"",
};
export default function appReducers(state = INITIAL_STATE, action) {
  let persistStates =
    get(localStorage.get("redux") || {}, "app") || INITIAL_STATE;
  let newState = {
    ...state,
    ...persistStates,
  };

  if (!isEqual(state, newState)) {
    state = newState;
  }

  switch (action.type) {
    case UPDATE_GLOBAL_MODAL_VISIBLE:
      state = {
        ...state,
        modalVisible: get(action, "visible") === true,
        modalType: get(action, "visible") !== true ? "" : state.modalType,
        modalRedirection:
          get(action, "visible") !== true ? "" : state.modalRedirection,
      };
      break;
    case UPDATE_GLOBAL_MODAL_TIMER:
      state = {
        ...state,
        modalVisible: get(action, "visible") === true,
        modalType: get(action, "visible") !== true ? "" : state.modalType,
        modalRedirection:
          get(action, "visible") !== true ? "" : state.modalRedirection,
        modalNotifyTime: get(action, "visible") !== true ? new Date() : null,
      };
      break;
    case TRIGGER_MODAL:
      state = {
        ...state,
        modalVisible: true,
        modalType: get(action, "payload.type"),
        modalRedirection: get(action, "payload.redirection"),
        exchange: get(action, "payload.exchange"),
      };
      break;
    case CLEAR_MODAL:
      state = {
        ...state,
        ...INITIAL_STATE,
      };
      break;
    default:
      break;
  }

  persistRedux("app", state);
  return state;
}
