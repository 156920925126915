// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-address-js": () => import("./../../../src/pages/address.js" /* webpackChunkName: "component---src-pages-address-js" */),
  "component---src-pages-api-key-js": () => import("./../../../src/pages/api_key.js" /* webpackChunkName: "component---src-pages-api-key-js" */),
  "component---src-pages-api-listing-js": () => import("./../../../src/pages/api_listing.js" /* webpackChunkName: "component---src-pages-api-listing-js" */),
  "component---src-pages-api-platform-js": () => import("./../../../src/pages/api_platform.js" /* webpackChunkName: "component---src-pages-api-platform-js" */),
  "component---src-pages-asset-js": () => import("./../../../src/pages/asset.js" /* webpackChunkName: "component---src-pages-asset-js" */),
  "component---src-pages-campaign-js": () => import("./../../../src/pages/campaign.js" /* webpackChunkName: "component---src-pages-campaign-js" */),
  "component---src-pages-customer-service-js": () => import("./../../../src/pages/customer_service.js" /* webpackChunkName: "component---src-pages-customer-service-js" */),
  "component---src-pages-deposit-details-js": () => import("./../../../src/pages/deposit_details.js" /* webpackChunkName: "component---src-pages-deposit-details-js" */),
  "component---src-pages-deposit-js": () => import("./../../../src/pages/deposit.js" /* webpackChunkName: "component---src-pages-deposit-js" */),
  "component---src-pages-deposit-list-js": () => import("./../../../src/pages/deposit_list.js" /* webpackChunkName: "component---src-pages-deposit-list-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-dynamic-capacity-js": () => import("./../../../src/pages/dynamic_capacity.js" /* webpackChunkName: "component---src-pages-dynamic-capacity-js" */),
  "component---src-pages-email-verification-js": () => import("./../../../src/pages/email_verification.js" /* webpackChunkName: "component---src-pages-email-verification-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot_password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-high-frequency-details-js": () => import("./../../../src/pages/high_frequency_details.js" /* webpackChunkName: "component---src-pages-high-frequency-details-js" */),
  "component---src-pages-high-frequency-js": () => import("./../../../src/pages/high_frequency.js" /* webpackChunkName: "component---src-pages-high-frequency-js" */),
  "component---src-pages-income-cap-home-js": () => import("./../../../src/pages/incomeCap_home.js" /* webpackChunkName: "component---src-pages-income-cap-home-js" */),
  "component---src-pages-income-cap-summary-js": () => import("./../../../src/pages/incomeCap_summary.js" /* webpackChunkName: "component---src-pages-income-cap-summary-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inter-transfer-js": () => import("./../../../src/pages/inter_transfer.js" /* webpackChunkName: "component---src-pages-inter-transfer-js" */),
  "component---src-pages-inter-transfer-list-js": () => import("./../../../src/pages/inter_transfer_list.js" /* webpackChunkName: "component---src-pages-inter-transfer-list-js" */),
  "component---src-pages-invite-friend-js": () => import("./../../../src/pages/invite_friend.js" /* webpackChunkName: "component---src-pages-invite-friend-js" */),
  "component---src-pages-liquidized-robot-list-js": () => import("./../../../src/pages/liquidized_robot_list.js" /* webpackChunkName: "component---src-pages-liquidized-robot-list-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-and-video-js": () => import("./../../../src/pages/newsAndVideo.js" /* webpackChunkName: "component---src-pages-news-and-video-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-product-high-frequency-detail-index-js": () => import("./../../../src/pages/product/high_frequency/detail/index.js" /* webpackChunkName: "component---src-pages-product-high-frequency-detail-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset_password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-reward-explore-community-js": () => import("./../../../src/pages/rewardExplore/community.js" /* webpackChunkName: "component---src-pages-reward-explore-community-js" */),
  "component---src-pages-reward-explore-index-js": () => import("./../../../src/pages/rewardExplore/index.js" /* webpackChunkName: "component---src-pages-reward-explore-index-js" */),
  "component---src-pages-reward-explore-node-team-js": () => import("./../../../src/pages/rewardExplore/nodeTeam.js" /* webpackChunkName: "component---src-pages-reward-explore-node-team-js" */),
  "component---src-pages-reward-explore-rewards-direct-reward-js": () => import("./../../../src/pages/rewardExplore/rewards/directReward.js" /* webpackChunkName: "component---src-pages-reward-explore-rewards-direct-reward-js" */),
  "component---src-pages-reward-explore-rewards-leader-reward-js": () => import("./../../../src/pages/rewardExplore/rewards/leaderReward.js" /* webpackChunkName: "component---src-pages-reward-explore-rewards-leader-reward-js" */),
  "component---src-pages-reward-explore-rewards-placement-reward-js": () => import("./../../../src/pages/rewardExplore/rewards/placementReward.js" /* webpackChunkName: "component---src-pages-reward-explore-rewards-placement-reward-js" */),
  "component---src-pages-reward-explore-rewards-vip-reward-js": () => import("./../../../src/pages/rewardExplore/rewards/vipReward.js" /* webpackChunkName: "component---src-pages-reward-explore-rewards-vip-reward-js" */),
  "component---src-pages-reward-explore-top-up-js": () => import("./../../../src/pages/rewardExplore/topUp.js" /* webpackChunkName: "component---src-pages-reward-explore-top-up-js" */),
  "component---src-pages-robot-js": () => import("./../../../src/pages/robot.js" /* webpackChunkName: "component---src-pages-robot-js" */),
  "component---src-pages-setting-js": () => import("./../../../src/pages/setting.js" /* webpackChunkName: "component---src-pages-setting-js" */),
  "component---src-pages-static-capacity-js": () => import("./../../../src/pages/static_capacity.js" /* webpackChunkName: "component---src-pages-static-capacity-js" */),
  "component---src-pages-transaction-js": () => import("./../../../src/pages/transaction.js" /* webpackChunkName: "component---src-pages-transaction-js" */),
  "component---src-pages-under-maintenance-js": () => import("./../../../src/pages/under_maintenance.js" /* webpackChunkName: "component---src-pages-under-maintenance-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-vip-capacity-js": () => import("./../../../src/pages/vip_capacity.js" /* webpackChunkName: "component---src-pages-vip-capacity-js" */),
  "component---src-pages-withdrawal-details-js": () => import("./../../../src/pages/withdrawal_details.js" /* webpackChunkName: "component---src-pages-withdrawal-details-js" */),
  "component---src-pages-withdrawal-js": () => import("./../../../src/pages/withdrawal.js" /* webpackChunkName: "component---src-pages-withdrawal-js" */),
  "component---src-pages-withdrawal-list-js": () => import("./../../../src/pages/withdrawal_list.js" /* webpackChunkName: "component---src-pages-withdrawal-list-js" */)
}

